<template>
  <AppToast />
  <VitePwaManifest />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <div id="fullscreen-animation-wrapper">
    <div id="color-mode-animation-element"></div>
  </div>
</template>

<script setup lang="ts">
const appConfigStore = useAppConfigStore();

const computedTheme = computed<string>(() => {
  return appConfigStore.colorMode === 'dim'
    ? '/css/theme/denim/theme-dim.css'
    : '/css/theme/denim/theme-light.css';
});

useHead({
  title: 'Apartment Permits',
  bodyAttrs: {
    class: 'main-body',
  },
  link: [
    {
      rel: 'stylesheet',
      href: computedTheme,
    },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon-180x180.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-32x32.png',
    },
  ],
});
</script>
